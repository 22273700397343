<template>
	<div class="showDetails">
		<div class="content">
			<div class="deviceData-title">
				<div class="institutionTotal">
					<img src="@/assets/imgs/peoples.png" alt="" />
					<div class="institutionTotal-content">
						<p>家庭编号</p>
						<p>{{ familyNumber }}</p>
					</div>
				</div>
			</div>
			<div class="familyContent clearfix">
				<p class="title">家庭成员</p>
				<div class="familyContent-item float-l" v-for="(item, index) in familyList" :key="index">
					<div class="familyContent-item-header">
						<img src="@/assets/imgs/avactor.png" alt="" />
						<div>
							<p>{{ item.title }}</p>
							<div class="tags">
								<img v-if="item.id == '男'" src="@/assets/imgs/man.png" alt="" />
								<img v-else src="@/assets/imgs/women.png" alt="" />
							</div>
						</div>
					</div>
					<div class="familyContent-item-content">
						<p>
							<span class="label">性别</span>
							<span>{{ item.id }}</span>
						</p>
						<p>
							<span class="label">年龄</span>
							<span>{{ item.age }}</span>
						</p>
						<p>
							<span class="label">民族</span>
							<span>{{ item.description }}</span>
						</p>
						<p>
							<span class="label">出生日期</span>
							<span>{{ item.link }}</span>
						</p>
						<p>
							<span class="label">联系电话</span>
							<span>{{ item.code }}</span>
						</p>
					</div>
					<img class="carImg" src="@/assets/imgs/carImg.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getAge } from '@/utils/formatTime';
export default {
	data() {
		return {
			familyList: [],
			familyNumber: '',
		};
	},
	mounted() {
		if (!this.$route.query.familyNumber) return this.$message.error('返回参数错误，请联系管理员');
		this.familyNumber = this.$route.query.familyNumber;
		let data = this.$route.query.personList.length > 0 ? JSON.parse(this.$route.query.personList) : [];
		this.familyList = data.map((item) => {
			return {
				...item,
				age: getAge(item.link),
			};
		});
	},
	methods: {
		getAge,
	},
};
</script>

<style lang="scss" scoped>
.showDetails {
	margin: 16px;
	.content {
		background-color: #fff;
		padding: 0 20px;
		padding-bottom: 16px;
	}
	.deviceData-title {
		padding: 16px 0;
		border-radius: 4px;
		margin-bottom: 12px;
		border-bottom: 1px solid #ebeef5;
		margin-bottom: 16px;
		.institutionTotal {
			display: flex;
			align-items: center;
			width: 100%;
			margin-right: 12px;
			background: #f9fafc;
			border-radius: 4px;
			padding: 16px;
			img {
				margin-right: 16px;
			}
			&-content {
				p {
					color: #999;
					font-size: 14px;
					height: 14px;
					line-height: 14px;
					&:last-child {
						font-size: 24px;
						line-height: 24px;
						height: 24px;
						margin-top: 12px;
						color: #333;
					}
				}
			}
		}
	}
	.familyContent {
		.title {
			font-size: 16px;
			color: #333;
			font-weight: 600;
			margin-bottom: 16px;
			line-height: 16px;
			height: 16px;
		}
		&-item {
			width: calc((100% - 48px) / 4);
			border: 1px solid rgba(197, 197, 197, 0.2);
			border-radius: 16px;
			box-shadow: 0px 3px 8px 0px rgba(8, 108, 181, 0.08);
			margin-bottom: 16px;
			position: relative;
			margin-right: 16px;
			&:nth-child(5n) {
				margin-right: 0;
			}
			&-header {
				border-radius: 16px 16px 0 0;
				// background: linear-gradient(178deg, rgba(134, 190, 255, 0.2) 0%, #ffffff 80%);
				background: linear-gradient(178deg, rgba(132, 245, 205, 0.2) 0%, #ffffff 80%);
				padding: 16px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid rgba(0, 0, 0, 0.02);
				img {
					width: 52px;
					height: 52px;
					margin-right: 16px;
				}
				div {
					p {
						font-size: 16px;
						color: #333;
						font-weight: 500;
						margin-bottom: 8px;
						line-height: 16px;
						height: 16px;
					}
					.tags {
						display: flex;
						align-items: center;
						// border: 1px solid #d0d0d0;
						// border-radius: 10px;
						// padding: 4px 8px;
						img {
							width: 12px;
							height: 12px;
							margin-right: 6px;
						}
						span {
							display: inline-block;
							font-size: 12px;
							color: #666;
							line-height: 12px;
							height: 12px;
						}
					}
				}
			}
			&-content {
				margin: 12px 16px 24px 16px;
				p {
					color: #333;
					font-size: 12px;
					line-height: 12px;
					height: 12px;
					margin-bottom: 12px;
					.label {
						display: inline-block;
						width: 60px;
						margin-right: 16px;
						color: #999999;
						font-weight: normal;
					}
				}
			}
			.carImg {
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}
	}
}
</style>